<!--
 * @Description: 商品预警详情弹框
 * @Autor: 胡椒
 * @Date: 2020-08-03 13:53:43
 * @LastEditors: 胡椒
 * @LastEditTime: 2020-08-03 14:36:21
-->

<template>
  <a-modal
    :visible="visible"
    title="预警详情"
    :width="600"
    :footer="null"
    @cancel="handleCancel"
  >
    <a-table
      :rowKey="(record) => record.code"
      :columns="columns"
      :dataSource="data"
      :pagination="false"
      :locale="{ filterConfirm: '暂无数据' }"
    ></a-table>
  </a-modal>
</template>

<script>
const columns = [
  {
    title: '发生时间',
    dataIndex: 'time',
  },
  {
    title: '预警类型',
    dataIndex: 'type',
  },
  {
    title: '描述',
    dataIndex: 'msg',
  },
];

export default {
  props: {
    // 是否显示弹框
    visible: {
      type: Boolean,
      default: false,
    },

    // 回显数据
    data: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isShow: this.visible,
      columns,
    };
  },

  methods: {
    // 关闭弹框
    handleCancel() {
      this.$emit('close');
    },
  },
};
</script>
