<!--
 * @Description: 商品预警
 * @Autor: 胡椒
 * @Date: 2020-08-03 09:55:52
 * @LastEditors: 胡椒
 * @LastEditTime: 2020-08-11 14:53:44
-->
<template>
  <div>
    <a-form :form="form" @submit="handleSubmit" layout="inline">
      <a-form-item>
        <a-select
          @change="setField"
          :defaultValue="currentField"
          style="width: 115px;"
          placeholder="请选择"
        >
          <a-select-option
            v-for="item in selectKeysOptions"
            :value="item.value"
            :key="item.value"
            >{{ item.label }}</a-select-option
          >
        </a-select>
      </a-form-item>

      <a-form-item>
        <a-input
          v-decorator="[currentField]"
          :placeholder="`请输入${currentFieldLabel}`"
          :key="currentField"
        />
      </a-form-item>

      <a-form-item>
        <a-select
          v-decorator="['warning_type', { initialValue: undefined }]"
          allowClear
          style="width: 140px;"
          placeholder="异常类型"
        >
          <a-select-option
            v-for="(item, index) in warningTypeOptions"
            :value="item.value"
            :key="index"
            >{{ item.label }}</a-select-option
          >
        </a-select>
      </a-form-item>

      <a-form-item :wrapperCol="{ span: 6 }">
        <a-button
          v-auth="'goods_manage_goods_warning_page'"
          type="primary"
          html-type="submit"
          :loading="loading"
          >查询</a-button
        >
      </a-form-item>
    </a-form>

    <a-row class="tool-bar">
      <a-button
        v-auth="'goods_manage_goods_cancel_warning'"
        type="primary"
        @click="removeConfirm"
        >解除预警</a-button
      >
    </a-row>

    <a-table
      :scroll="{ x: 1200, y: tableScrollY }"
      v-auth="'goods_manage_goods_warning_page'"
      :rowKey="(record) => record.id"
      :columns="columns"
      :dataSource="list"
      :pagination="pagination"
      :loading="loading"
      :locale="{ filterConfirm: '暂无数据' }"
      :row-selection="{
        selectedRowKeys: selectedRowKeys,
        onChange: onSelectChange,
      }"
      @change="handleTableChange"
    >
      <template slot="channel" slot-scope="record">
        <a-tag color="purple" v-if="record.kuran_goods__goods_channel == 1">{{
          record.kuran_goods__goods_channel | goodsChannelFilter
        }}</a-tag>
        <a-tag color="orange" v-if="record.kuran_goods__goods_channel == 2">{{
          record.kuran_goods__goods_channel | goodsChannelFilter
        }}</a-tag>
      </template>

      <template slot="operation" slot-scope="record">
        <a-row type="flex" justify="center" :gutter="[16, 16]">
          <a-col >
            <a-button
              size="small"
              @click="show(record.warning_detail)"
              v-auth="'goods_manage_goods_cancel_warning'"
              >预警详情</a-button
            >
          </a-col>

          <a-col >
            <a-button
              size="small"
              @click="showGoods(record.kuran_goods__item_id_id)"
              >商品管理</a-button
            >
          </a-col>
        </a-row>
      </template>
    </a-table>

    <!-- 预警详情 -->
    <ModalWarningDetail
      :visible="isModalVisible"
      :data="currentRecord"
      @close="isModalVisible = false"
    ></ModalWarningDetail>
  </div>
</template>

<script>
import listMixin from '@/mixin/listMixin';
import { getWarningList, updateWarningStatus } from '@/service/goods';
import ModalWarningDetail from '@/components/admin/goods/modal-warning-detail';
import { value2label } from '@/utils/index';

const selectKeysOptions = [
  { label: '商品ID', value: 'item_id' },
  { label: '商品名称', value: 'item_name' },
];

const dftValue = {
  item_id: '',
  item_name: '',
};

// 异常类型
const warningTypeOptions = [
  {
    label: '价格异常',
    value: 'price',
  },
  {
    label: '佣金异常',
    value: 'commission',
  },
  {
    label: '优惠券异常',
    value: 'coupon',
  },
];

const columns = [
  {
    title: '商品ID',
    fixed: 'left',
    dataIndex: 'kuran_goods__item_id_id',
    align: 'center',
    width: 200,
  },
  {
    title: '商品名称',
    dataIndex: 'kuran_goods__item_name',
    width: 400,
  },
  {
    title: '商品渠道',
    align: 'center',
    scopedSlots: { customRender: 'channel' },
  },
  {
    title: '异常类型',
    dataIndex: 'warning_types',
  },
  {
    title: '操作',
    align: 'center',
    fixed: 'right',
    width: 200,
    scopedSlots: { customRender: 'operation' },
  },
];

export default {
  name: 'warningList',
  mixins: [listMixin],
  components: {
    ModalWarningDetail,
  },
  data() {
    return {
      tableScrollY: 600,
      // 商品预警弹框
      isModalVisible: false, // 是否显示
      currentRecord: [], // 数据载体

      // 检索用表单
      form: this.$form.createForm(this),
      warningTypeOptions,

      // 列表相关
      columns: columns,
      selectedRowKeys: [], // 当前选中的列
      searchParams: {
        ...dftValue,
      },

      // 下拉options
      currentField: selectKeysOptions[0].value,
      selectKeysOptions,
    };
  },

  computed: {
    // 列表当前是否有被选中的列
    hasSelected() {
      return this.selectedRowKeys.length > 0;
    },

    // input的placeholder
    currentFieldLabel() {
      return value2label(this.currentField, selectKeysOptions);
    },
  },

  created() {
    this.getList();
    var windowHeight = document.documentElement.clientHeight || 1000;
    this.tableScrollY = windowHeight - 335;
  },

  methods: {
    // 列表数据检索
    async getList() {
      this.loading = true;
      const { res } = await getWarningList({
        ...this.searchParams,
        page: this.pagination.current,
        page_size: this.pagination.pageSize,
      });
      this.loading = false;
      if (res && res.success) {
        this.list = res.data.results;
        this.pagination = Object.assign(
          { ...this.pagination },
          { total: res.data.count }
        );
      }
    },

    // 检索表单提交
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          const proload = Object.assign({}, dftValue, values);
          this.searchParams = Object.assign({}, this.searchParams, proload);
          this.getList();
        }
      });
    },

    // 解除预警处理
    async removeConfirm() {
      if (!this.hasSelected) {
        this.$message.warning('请先选择要操作的数据');
        return;
      }
      this.$confirm({
        title: '解除预警',
        content: '请确保已处理所有相关异常信息后再解除预警',
        okText: '确认解除',
        onOk: () => {
          const ids = this.selectedRowKeys.join(',');
          this.doRemove(ids);
        },
      });
    },

    async doRemove(ids) {
      const { res } = await updateWarningStatus({ ids });
      if (res.success) {
        this.pagination.current = 1;
        this.getList();
        this.$message.success('操作成功');
      }
    },

    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    },

    // 新标签打开商品列表页
    showGoods(goodsId) {
      if (goodsId) {
        const url = `${location.origin}/admin/goods/list?id=${goodsId}`;
        window.open(url, '_blank');
      }
    },

    // 显示预警详情弹框
    show(record) {
      this.currentRecord = record;
      this.showModal(true);
    },

    showModal(flag) {
      this.isModalVisible = !!flag;
      if (!this.isModalVisible) {
        this.currentRecord = [];
      }
    },

    setField(value) {
      this.currentField = value;
    },
  },
};
</script>

<style lang="less" scoped>
.tool-bar {
  margin: 10px 0;
}
</style>
